@mixin responsvie($screen){
    @if ($screen==phone){
        @media screen and (max-width:500px){@content};
    }
    @if ($screen==desktop){
        @media screen and (min-width:501px){
            @content
        };
    }
}
.toastContainer{
    z-index: 999;
}
@include responsvie(desktop){
 .landing{
    *{
        margin: unset;
    }
     width: 100%;
     height: fit-content;
     padding: 47px 0;
     box-sizing: border-box;
     background-color: #fff;


     .intro{
           width: 100%;
           position: relative;
           padding: 0 80px;
           box-sizing: border-box;
           height: 100vh;
           .gradient{
            background-image: url(../assets/png/gradient.png);
            width: 25%;
            position: absolute;
            bottom:0px;
            left: 0;
                 
            img{
                width: 100%;
            }
           }
           .right{
               position: absolute;
               right: 0;
               bottom: 0;
               width: 25%;
               img{
                width: 100%;
               }
           }
        .heading{
           width: 100%;
           display: flex;
           justify-content: space-between;
           align-items: center;

           .img{
            width:150px;

            img{
                width: 100%;
            }
           }
           .download_links{
               display: flex;
               align-items: center;
           }
        }
        .write_up{
            width: 100%;
            text-align: center;
            margin-top: 24px;
          .text{
              margin: auto;
              width: 65%;

              .bottom{
                  margin: auto;
                  display: flex;
                  width: fit-content;
                  align-items: center;

                  span{
                      margin: 12px;
                      padding: 0;color: #6F6C90;
                      font-family: Outfit;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 23px;
letter-spacing: 0em;
text-align: center;


                  }
              }
            p.top{
                color: #5D5FEF;
                font-family: Outfit;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 18px;
                letter-spacing: 0.24em;
                text-align: center;
                text-transform: uppercase;
                
            }
            h1{
                color: #170F49;
                font-family: Outfit;
                font-size: 50px;
                font-style: normal;
                font-weight: 700;
                line-height: 62px;
                letter-spacing: 0px;
                text-align: center;
                margin: 0;
                
            }
            p.desc{
                color: #6F6C90;
                font-family: Outfit;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 30px;
                letter-spacing: 0em;
                text-align: center;
                
            }
         .next{display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;

            button{
                margin-top: 0;
            }
           
          }
            span{
                width: fit-content;
                padding: 24px 40px;
                border-radius: 16px;
                font-family: Outfit;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: 20px;
letter-spacing: 0px;
text-align: center;
        margin: 24px;
            }
            

            span.get_started{
                background: #5D5FEF;
                box-shadow: 0px 3px 12px 0px #4A3AFF2E;
                color: white;

            }
            span.login{
                color: #5D5FEF;
                background: #E3E3FC;
                
            }
        }
        }
     }

     .tab2{
         width: 100%;
         display: flex;
         padding: 90px;
         box-sizing: border-box;
  


         .image{
             display: flex;
             justify-content: center;
             align-items: center;
         }

         .write_up{
             .heading{
                 display: flex;
                  color: #E3E3FC;
                  align-items: center;
                  position: relative;
                  top: -40px;
                 .active{
                    font-family: Outfit;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 21px;
                    letter-spacing: 0em;
                    text-align: left;
                    background: #E3E3FC;
                    color: #5D5FEF;
                    padding: 12px 17px ;
                    margin-right: 24px;
                    border-radius: 16px;


                    
                 }
             }
             h1{
                color: #170F49;
                
font-family: DM Sans;
font-size: 50px;
font-style: normal;
font-weight: 700;
line-height: 62px;
letter-spacing: 0px;
text-align: left;


             }
             .lists{
                 width: 100%;
             }
             .lists>div{
                 width: 100%;
                 display: flex;
                 align-items: flex-start;
                 justify-content: space-between;

                 .comment{
                     width: 85%;
                     h3{
                        font-family: Outfit;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 20px;
                        letter-spacing: 0em;
                        text-align: left;
                        color: #170F49;
                        margin-top: 0;
                     }
                     p{
                        color: #6F6C90;
                        font-family: OutfitR;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 30px;
letter-spacing: 0em;
text-align: left;


                     }
                 }
             }
         }
        
     }
     .tab2>div{
        flex-basis: 50%;
        width: 50%;

        img{
            width: 80%;
        }
    }
    .miscs{
        width: 100%;

        img{
            width: 100%;
        }
    }
    .features{
        width: 100%;
        padding:90px 80px;
        box-sizing: border-box;
        background: #F7F7FB;
        text-align: center;
        
        h3{
            color: #170F49;
            //styleName: Headings/Typography Size 2;
// font-family: DM Sans;
font-size: 34px;
font-style: normal;
font-weight: 700;
line-height: 46px;
letter-spacing: 0px;
text-align: center;


        }
        .tabs{
            display: flex;
            width: 100%;
            justify-content: space-between;

            .tab{
                width: 30%;
                
                background: #FFFFFF;
                border: 1px solid #EFF0F6;
                border-radius: 16px;
                box-shadow: 0px 5px 14px 0px #080F340A;
                padding: 24px;
                box-sizing: border-box;
                h4{
                    font-family: Outfit;
font-size: 22px;
font-style: normal;
font-weight: 700;
line-height: 28px;
letter-spacing: 0px;
text-align: center;
color: #170F49;


                }
                p{
                    color: #6F6C90;
//styleName: Paragraph/Default;
// font-family: DM Sans;
font-size: 18px;
font-style: normal;
font-weight: 100;
line-height: 30px;
letter-spacing: 0em;
text-align: center;

                }

            }
        }
    }
    .about_us{
        width: 100%;
        // height: 0vh;
        padding:80px 100px;
        box-sizing: border-box;
        display: flex;
        background: #020317;
        align-items: center;
        // color: white;
        
        div{
            width: 90%;
            color: #F1F1FE;
            
            h3{
                font-family: Outfit;
font-size: 14px;
font-weight: 600;
line-height: 38px;
letter-spacing: 3px;
text-align: left;
text-transform: uppercase;
margin-bottom: 0;

            }
            h1{
                font-family: Outfit;
                 font-size: 40px;
                font-weight: 500;
               line-height: 46px;
letter-spacing: 0px;
text-align: left;

            }
            p{
                font-family: Outfit;
font-size: 20px;
font-weight: 400;
line-height: 43px;
letter-spacing: 0px;
text-align: left;

            }
            .img{
                width: 80%;
                height: 80%;
                margin: auto;
                img{
                    width: 100%;
                }
            }
        }
    }
    .contact_us{
        width: 100%;
        display: flex;
        padding: 80px;
        box-sizing: border-box;

        div{
            width: 50%;
            .head{
                
                font-family: Outfit;
font-size: 14px;
font-weight: 600;
line-height: 38px;
letter-spacing: 3px;
text-align: left;
color: #5D5FEF;

            }
            h2{
                color: #020317;
                font-family: Outfit;
font-size: 36px;
font-weight: 500;
line-height: 46px;
letter-spacing: 0px;
text-align: left;
margin-bottom: 0;


            }
            span{
                font-family: Outfit;
    font-size: 20px;
    font-weight: 500;
    line-height: 46px;
    letter-spacing: 0px;
    text-align: left;
    color: #9898A2;

            }

            .contacts{
                margin-top: 24px;
                display: flex;
                flex-direction: column;
            }
            a{
                border: 1px solid #EEEEF0;
                color: #A6A6AE;
                font-family: Outfit;
                width: fit-content;
                display: flex;
                align-items: center;
font-size: 12px;
font-weight: 200;
line-height: 20px;
letter-spacing: 0px;
text-align: left;
text-decoration: none;
         padding: 8px;
         margin: 10px;

         svg{
             margin-right: 12px;
         }
            }

            form{
                width: 80%;
                // height: 80%;
                background: #F7F7FB;
                display: flex;
                flex-direction: column;
                padding: 56px;
                box-sizing: border-box;
                align-items: center;

                textarea,input{
                    width: 100%;
                    padding: 15px 20px;
                    box-sizing: border-box;
                    background: white;
                    border-radius: 10px;
                    border: none;
                    margin-bottom: 24px;
                    height: 56px;
                    outline: none;

                }
                textarea{
                    height: 100px;
                    width: 100%;
                    background-color: white;
                    outline: none;
                    padding: 15px 20px;
                    box-sizing: border-box;
                }
                ::placeholder{
                    color: #C2C2CA;
                    font-family: Outfit;
                    text-transform: capitalize;
font-size: 16px;
font-weight: 400;
line-height: 27px;
letter-spacing: 0em;
text-align: left;
 

                }
            }
        }
    }
    footer{
        div.top {height: 720px;
         background: #5D5FEF;
         padding: 0px 60px;
         display: flex;
         position: relative;
         box-sizing: border-box;

         div.left{
             width: 50%;
             position: relative;
             height: 100%;
             bottom: 40px;
             background: none !important;
             left: 0;
            //  padding: 0 0 0 40px;
             img{
                 width: 100%;
                 height: 100%;

             }
         }
         div.right{
            color: #FFFFFF;
//styleName: Headings/Typography Size 2;
font-family: DM Sans;
font-size: 34px;
font-weight: 700;
line-height: 46px;
letter-spacing: 0px;
text-align: left;
width: 30%;
display: flex;
flex-direction: column;
height: 100%;
justify-content: center;

         }
        }
        .links{
            display: flex;
            width: 100%;
            padding: 30px;
            box-sizing: border-box;
            justify-content: space-between;

            a{
                text-decoration: none;
                margin: 12px;
                color: #16192C;
                font-family: Outfit;
                font-size: 16px;
                font-weight: 400;
                line-height: 20px;
                letter-spacing: 0px;
                text-align: left;
                
            }
        }

    }
 }   

}
@include responsvie(phone){
 .landing{

    // .bottom_link{
       
    // }
    * {
            margin: unset;
        }
     width: 100%;
     height: fit-content;
     padding: 47px 0;
     box-sizing: border-box;
     background-color: #fff;


     .intro{
           width: 100%;
           position: relative;
           padding: 0 30px;
           box-sizing: border-box;
           
           .gradient{
            background-image: url(../assets/png/gradient.png);
            width: 50%;
            position: absolute;
            bottom:-30px;
            left: 0;
            display: none;
                 
            img{
                width: 100%;
            }
           }
           .right{
               position: absolute;
               right: 0;
               bottom: -50px;
               width: 50%;
               display: none;

               img{
                   width: 100%;
               }
           }
        .heading{
           width: 100%;
           display: flex;
           justify-content: space-between;
           align-items: center;

           .download_links{
               display: flex;
               flex-direction: column;
               align-items: center;
           }

           .img{
               width: 100px;

               img{
                   width: 100%;
               }
           }
        }
        .write_up{
            width: 100%;
            text-align: center;
            margin-top: 24px;
          .text{
              margin: auto;
              width: 85%;

              .bottom{
                  margin: auto;
                  display: flex;
                  width: fit-content;
                  align-items: center;

                  span{
                      margin: 12px;
                      padding: 0;color: #6F6C90;
                      font-family: Outfit;
                      font-size: 16px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: 23px;
                      letter-spacing: 0em;
                      text-align: center;
                      


                  }
              }
            p.top{
                color: #5D5FEF;
                font-family: Outfit;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 18px;
                letter-spacing: 0.24em;
                text-align: center;
                
                
            }
            h1{
                color: #170F49;
                font-family: Outfit;
font-size: 30px;
font-style: normal;
font-weight: 700;
line-height: 38px;
letter-spacing: 0px;
text-align: center;

                margin: 0;
                
            }
            p.desc{
                color: #6F6C90;
                font-family: Outfit;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 30px;
                letter-spacing: 0em;
                text-align: center;
                
                
            }
         .next{display: flex;
            width: 100%;
            justify-content: center;
            flex-direction: column;
           
          }
            span{
                width: fit-content;
                padding: 24px 40px;
                border-radius: 16px;
                font-family: Outfit;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: 20px;
letter-spacing: 0px;
text-align: center;
        margin: 24px 0;
            }
            

           

            
            span.login{
                color: #5D5FEF;
                background: #E3E3FC;
                width: 100%;
                box-sizing: border-box;
                
            }
        }
        }
     }

     .tab2{
         width: 100%;
         display: flex;
         padding: 30px;
         flex-direction: column;
         box-sizing: border-box;
         position: relative;
         z-index:99 ;
         background-color: #FFFFFF;
  


         .image{
             display: flex;
             justify-content: center;
             align-items: center;
             display: none;
         }

         .write_up{
             .heading{
                 display: flex;
                  color: #E3E3FC;
                  align-items: center;
                //   position: relative;
                  top: 20px;
                 .active{
                    font-family: Outfit;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 21px;
                    letter-spacing: 0em;
                    text-align: left;
                    background: #E3E3FC;
                    color: #5D5FEF;
                    padding: 12px 17px ;
                    margin-right: 24px;
                    border-radius: 16px;


                    
                 }
             }
             h1{
                color: #170F49;
                font-family: Outfit;
font-size: 26px;
font-style: normal;
font-weight: 700;
line-height: 33px;
letter-spacing: 0px;
text-align: left;
margin-top: 24px;

             }
             .lists{
                display: flex;
                flex-direction: column;
                 width: 100%;    gap: 24px;
                 margin-top: 18px;
                 margin-bottom: 40px;
             }
             .lists>div{
                 width: 100%;
                 display: flex;
                 align-items: flex-start;
                 justify-content: space-between;
             

                 .comment{
                     width: 85%;
                     h3{
                        font-family: Outfit;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 20px;
                        letter-spacing: 0em;
                        text-align: left;
                        margin: .5em 0;
                        
                        color: #170F49;
                        margin-top: 0;
                     }
                     p{
                        color: #6F6C90;
                        font-family: OutfitR;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 34px;
                        letter-spacing: 0em;
                        text-align: left;
                        


                     }
                 }
             }
         }
        
     }
     .tab2>div{
        flex-basis:100%;
        width: 100%;

        img{
            width: 80%;
        }
    }
    .miscs{
        width: 100%;
        overflow: hidden;

        img{
            width: 900px;
        }
    }
    .features{
        width: 100%;
        padding:30px;
        box-sizing: border-box;
        background: #F7F7FB;
        
        
        h3{
            color: #170F49;
            //styleName: Headings/Typography Size 2;
// font-family: DM Sans;
font-size: 34px;
font-style: normal;
font-weight: 700;
line-height: 46px;
letter-spacing: 0px;
text-align: center;


        }
        .tabs{
            display: flex;
            width: 100%;
            justify-content: space-between;
            flex-direction: column;

            .tab{
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                background: #FFFFFF;
                border: 1px solid #EFF0F6;
                border-radius: 16px;
                box-shadow: 0px 5px 14px 0px #080F340A;
                padding: 24px;
                box-sizing: border-box;
                margin-bottom: 24px;
                h4{
                    font-family: Outfit;
font-size: 22px;
font-style: normal;
font-weight: 700;
line-height: 28px;
letter-spacing: 0px;
text-align: center;
color: #170F49;
margin-bottom: 10px;


                }
                p{
                    color: #6F6C90;
                    font-family: Outfit;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 30px;
                    letter-spacing: 0em;
                    text-align: center;
                    margin: 0;
                    

                }

            }
        }
    }
    .about_us{
        width: 100%;
        // height: 0vh;
        padding:30px;
        box-sizing: border-box;        
        background: #020317;
        align-items: center;
        // color: white;
        
        div{
            width: 100%;
            color: #F1F1FE;
            
            h3{
                font-family: Outfit;
font-size: 12px;
font-weight: 600;
line-height: 38px;
letter-spacing: 3px;
text-align: left;
text-transform: uppercase;
margin-bottom: 0;

            }
            h1{
                font-family: Outfit;
                 font-size: 28px;
                font-weight: 500;
               line-height: 46px;
letter-spacing: 0px;
text-align: left;

            }
            p{
                font-family: Outfit;
font-size: 16px;
font-weight: 400;
line-height: 43px;
letter-spacing: 0px;
text-align: left;

            }
            .img{
                width: 80%;
                height: 80%;
                margin: auto;
                img{
                    width: 100%;
                }
            }
        }
    }
    .contact_us{
        width: 100%;
        // display: flex;
        padding: 30px;
        // flex-direction: column;
        box-sizing: border-box;

        div{
            width: 100%;
            .head{
                
                font-family: Outfit;
font-size: 12px;
font-weight: 600;
line-height: 38px;
letter-spacing: 3px;
text-align: left;
color: #5D5FEF;

            }
            h2{
                color: #020317;
                font-family: Outfit;
font-size: 24px;
font-weight: 500;
line-height: 46px;
letter-spacing: 0px;
text-align: left;
margin-bottom: 0;


            }
            span{
                font-family: Outfit;
    font-size: 20px;
    font-weight: 500;
    line-height: 46px;
    letter-spacing: 0px;
    text-align: left;
    color: #9898A2;

            }

            .contacts{
                margin-top: 24px;
                display: flex;
                flex-direction: column;
            }
            a{
                border: 1px solid #EEEEF0;
                color: #A6A6AE;
                font-family: Outfit;
                width: fit-content;
                display: flex;
                align-items: center;
font-size: 12px;
font-weight: 200;
line-height: 20px;
letter-spacing: 0px;
text-align: left;
text-decoration: none;
         padding: 8px;
         margin: 10px;

         svg{
             margin-right: 12px;
         }
            }

            form{
                width: 100%;
                // height: 80%;
                background: #F7F7FB;
                display: flex;
                flex-direction: column;
                padding: 24px;
                box-sizing: border-box;
                align-items: center;

                textarea,input{
                    width: 100%;
                    padding: 15px 20px;
                    box-sizing: border-box;
                    background: white;
                    border-radius: 10px;
                    border: none;
                    margin-bottom: 24px;
                    height: 56px;
                    outline: none;

                }
                textarea{
                    height: 100px;
                    width: 100%;
                    background-color: white;
                    outline: none;
                    padding: 15px 20px;
                    box-sizing: border-box;
                }
                ::placeholder{
                    color: #C2C2CA;
                    font-family: Outfit;
                    text-transform: capitalize;
font-size: 16px;
font-weight: 400;
line-height: 27px;
letter-spacing: 0em;
text-align: left;
 

                }
            }
        }
    }
    footer{
        div.top {
         background: #5D5FEF;
         padding: 0px 60px;
        //  display: flex;
         position: relative;
         box-sizing: border-box;

         div.left{
             width: 100%;
             position: relative;
             height: 80%;
             bottom: 40px;
             background: none !important;
             left: 0;
            //  padding: 0 0 0 40px;
             img{
                 width: 100%;
                 height: 100%;

             }
         }
         div.right{
            color: #FFFFFF;
//styleName: Headings/Typography Size 2;
font-family: DM Sans;
font-size: 28px;
font-weight: 700;
line-height: 46px;
letter-spacing: 0px;
text-align: left;
width: 100%;
display: flex;
flex-direction: column;
height: 100%;
justify-content: center;

.download_links{
    display: flex;
    align-items: center;
}

         }
        }
        .links{
            display: flex;
            width: 100%;
            padding: 12px 30px;
            box-sizing: border-box;
            justify-content: space-between;
            align-items: center;
            flex-direction: column;
                gap: 24px;

            a{
                text-decoration: none;
                margin: 12px;
                color: #16192C;
                font-family: Outfit;
                font-size: 16px;
                font-weight: 400;
                line-height: 20px;
                letter-spacing: 0px;
                text-align: left;
                
            }
        }

    }
    padding-bottom: 0;
 }   

}