@mixin responsive($screen) {
  @if ($screen==phone) {
    @media screen and (max-width: 500px) {
      @content;
    }
  }
  @if ($screen==desktop) {
    @media screen and (min-width: 501px) {
      @content;
    }
  }
}

@include responsive(phone) {
  .button {
    width: 100%;
    font-family: Outfit;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0em;
    color: #ffffff;
    background: #5d5fef;
    border: none;
    padding: 14px 0;
    border-radius: 16px;
    // margin-top: 60px;

    // text-align: left;
  }
  .mainApp {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: scroll;

    nav {
      width: 100%;
      position: fixed;
      bottom: 0;
      display: flex;
      justify-content: space-between;
      padding: 10px 24px;
      height: 3em;
      box-sizing: border-box;
      z-index: 9999999;
      align-items: center;
      left: 0;
      background-color: #ffffff;
    }
    main {
      width: 100%;
      min-height: 95%;
      //  padding: 24px;
      box-sizing: border-box;
      background-color: #f1f1fe;
      overflow: scroll;
      //  position: fixed;
    }
    .post_errand {
      position: fixed;
      right: 30px;
      bottom: 90px;
      z-index: 9999;
    }
  }
  .empty_state {
    // padding: 0 !important;
    height: 100% !important;

    .img + .instruction {
      text-align: center;

      h3 {
        width: 100%;
        text-align: center;
      }
    }
    button {
      margin-top: 40px;
    }
  }
}
@include responsive(desktop) {
  .button {
    width: 280px;
    font-family: Outfit;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0em;
    color: #ffffff;
    background: #5d5fef;
    border: none;
    padding: 14px 0;
    border-radius: 16px;
    // margin-top: 60px;

    // text-align: left;
  }
}

.desktop {
  display: flex;
  height: 100vh;
  overflow: scroll;
  background: #F1F1FE;

  .dash_board {
    width: 25%;
    box-shadow: 1px 0px 0px 0px #f3f3f4;
    height: 100%;
    background-color: #ffffff;
    padding: 24px;
    box-sizing: border-box;

    .top {
      display: flex;
      align-items: center;
      height: 80px;
      background: none;
      padding: 24px 30px;
      box-sizing: border-box;

      .name_username {
        h4 {
          margin: 0;
        }
        height: 100%;
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        span {
          color: #aeaeb9;

          font-family: Outfit;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 21px;
          letter-spacing: 0em;
          text-align: left;
        }
      }
      .avatar {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin: 16px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .navs {
      margin-top: 32px;
      display: flex;
      flex-direction: column;
      gap: 24px;

      >nav{
        display: flex;
        flex-direction: column;
        gap: 24px;
      }

      p {
        width: 100%;
        color: #abacd5;
        font-family: Outfit;
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;
      }
      a {
        text-decoration: none;
        display: flex;
        align-items: center;

        p {
          padding: 12px;
        }
      }
    }
  }

  .content{
    width: 75%;
    position: relative;
    padding: 32px;
    

    .routes{
        // padding-top: 5em;
        width: 100%;

    ::-webkit-scrollbar{
        display: none;
    }
        .home,.negotiation{
            max-height: 90vh;
            overflow: scroll;
            position: relative;
            width: 60%;
        }

        .profile{
            display: flex;
            justify-content: space-between;

            .options{
                width: 40%;
                gap: 24px;
                  display: flex;
                  flex-direction: column
            }
            .body{
                width: 50%;
                margin-top: 40px;
                max-height: 90vh;
                overflow: scroll;

                .body{
                    width: 100%;
                    flex-wrap: wrap;
                    display: flex;
                    gap: 32px;

                    .setting_tab{
                        width: 46%;
                    }
                }
            }
        }
    }
    .notification_upload{
        top:10px;
        position: absolute;
        right: 0;
        display: flex;
        justify-content: end;
        align-items: center;
        gap: 32px;

        a{
            border-radius: 50%;
            height: 52px;
            width: 52px;
            background: white;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .button{
            margin: 0 !important;
            margin-right: 32px !important;
            width: 180px !important;
        }
    }
  }
}
